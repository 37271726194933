<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex mb-1 xs12 class="text-xs-center" mt-1>
        <h1>Imprimir Etiquetas</h1>
      </v-flex>
    </v-layout>
    <hr />

    <v-container grid-list-md text-xs-center>
      <v-layout row wrap align-center justify-center>
        <v-flex xs8>
          <v-alert outlined type="warning" :value="true">
            Este módulo crea etiquetas con un QR fijo para el total de escaneos
            de IMEI. EL MÓDULO NO GENERA ÓRDENES EN THOR
          </v-alert>
          <!-- <p>{{ objetoEnviado }}</p> -->
        </v-flex>
        <!-- <v-flex xs10>
            <v-alert outlined type="warning" value="true" class="mb-3">
              <strong>Advertencia: </strong> Si la lista de etiquetas no se genera
              completa, puede que algunos pedidos no la tengan.
            </v-alert>
          </v-flex> -->
        <v-flex xs11>
          <div class="d-flex flex-row">
            <!-- IMPRIMIR POR CLIENTE -->
            <div v-if="1 === 2" class="mr-5">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                  <v-card-title class="blue white--text">
                    <h3><strong>PDF por cliente</strong></h3>
                  </v-card-title>
                  <v-menu
                    ref="menuFecha"
                    v-model="menuFecha"
                    :close-on-content-click="false"
                    :return-value.sync="fechaPdf"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaPdf"
                        label="Fecha"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="mx-5 mt-3"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaPdf" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuFecha.save(fechaPdf)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-autocomplete
                    v-model="clienteSeleccionadoPorCliente"
                    :items="clientes"
                    item-text="name_customer"
                    item-value="_id"
                    label="Cliente"
                    class="mx-5"
                    required
                  ></v-autocomplete>

                  <v-select
                    v-model="cantidadSeleccionada"
                    :items="etiquetasPorHoja"
                    label="Etiquetas por hoja"
                    class="mx-5"
                    required
                  ></v-select>

                  <!-- <v-select
                      v-if="
                        clienteSeleccionadoPorCliente ===
                          '5fb7baf4c8c6410667c3f1bb'
                      "
                      v-model="tipoEtiqueta"
                      :items="tipos"
                      :rules="tipoRules"
                      label="Tipo de Entrega"
                      class="mx-5"
                      required
                    ></v-select> -->

                  <v-divider class="mb-2"></v-divider>

                  <!-- <v-card-actions> -->
                  <v-btn elevation="2" color="info" @click="limpiarTodo"
                    >Limpiar</v-btn
                  >
                  <v-btn
                    :disabled="deshabilitarPorCliente"
                    elevation="2"
                    color="success"
                    @click="generarEtiquetasPorCliente"
                    >Generar PDF</v-btn
                  >
                  <v-btn
                    elevation="2"
                    color="#311DEF"
                    class="white--text"
                    :loading="cargandoPdfCliente"
                    :disabled="deshabilitarPdfCliente"
                    ><a :href="etiquetasPorCliente" target="_blank">Descargar</a
                    ><v-icon class="ml-2">download</v-icon></v-btn
                  >
                  <!-- </v-card-actions> -->
                  <v-flex class="mx-3">
                    <v-alert
                      v-if="noEtiquetas"
                      outlined
                      type="error"
                      :value="true"
                    >
                      {{ noEtiquetasMensaje }}
                    </v-alert>
                  </v-flex>
                  <v-flex class="mx-3">
                    <v-alert
                      v-if="errorPdfCliente"
                      outlined
                      type="error"
                      :value="true"
                    >
                      Error al generar PDF. Comunícate con el equipo TI!
                    </v-alert>
                  </v-flex>
                </v-card>
              </v-form>
            </div>
            <!-- FIN IMPRIMIR POR CLIENTE -->

            <!-- IMPRIMIR POR PEDIDOS -->
            <div>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                  <v-card-title class="blue white--text">
                    <h3><strong>PDF por pedidos</strong></h3>
                  </v-card-title>
                  <v-autocomplete
                    v-model="clienteSeleccionado"
                    :items="clientes"
                    item-text="name_customer"
                    item-value="_id"
                    label="Cliente"
                    class="mx-5 mt-3"
                    required
                  ></v-autocomplete>

                  <v-autocomplete
                    v-model="QR"
                    :items="QRs"
                    item-text="name_qr"
                    item-value="id_qr"
                    label="Código QR"
                    class="mx-5 mt-3"
                    required
                  ></v-autocomplete>
                  <v-textarea
                    solo
                    v-model="listaPedidos"
                    label="Pega lista de pedidos"
                    :value="listaPedidos"
                    class="mx-5"
                  ></v-textarea>
                  <v-divider class="mb-2"></v-divider>
                  <v-btn
                    class="ma-2"
                    elevation="2"
                    color="info"
                    @click="limpiarTodo"
                    >Limpiar</v-btn
                  >
                  <v-btn
                    class="ma-2"
                    :disabled="deshabilitarPorPedido"
                    elevation="2"
                    color="success"
                    @click="generarEtiquetasPorPedidos"
                    >Generar PDF</v-btn
                  >
                  <v-btn
                    elevation="2"
                    color="#311DEF"
                    class="ma-2 white--text"
                    :loading="cargandoPdfPedidos"
                    :disabled="deshabilitarPdfPedidos"
                    ><a :href="etiquetasPorPedidos" target="_blank">Descargar</a
                    ><v-icon class="ml-2">mdi-download</v-icon></v-btn
                  >
                  <v-flex>
                    <v-alert
                      class="mx-3"
                      v-if="errorPdfPedidos"
                      outlined
                      type="error"
                      :value="true"
                    >
                      Error al generar PDF. Pedido(s) no encontrado(s)!
                    </v-alert>
                  </v-flex>
                </v-card>
              </v-form>
            </div>
            <!-- FIN IMPRIMIR POR PEDIDOS -->
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>
<script>
import axios from "axios";
import firebase from "firebase";
export default {
  data() {
    return {
      QR: { id_qr: "idqr2323232", name_qr: "modelo lanzamiento" },
      QRs: [{ id_qr: "idqr2323232", name_qr: "modelo lanzamiento" }],
      clientes: [],
      clienteSeleccionado: {
        _id: "6099a6de2f22fc6ae8af82bc",
        name_customer: "MIGPS",
      },
      clienteSeleccionadoPorPedido: "6099a6de2f22fc6ae8af82bc",
      clienteSeleccionadoPorCliente: "",
      listaPedidos: "",
      listaPedidosFiltrada: "",
      etiquetasPorPedidos: "",
      etiquetasPorCliente: "",
      cargandoPdfPedidos: false,
      cargandoPdfCliente: false,
      deshabilitarPdfPedidos: true,
      deshabilitarPdfCliente: true,
      errorPdfCliente: false,
      errorPdfPedidos: false,
      etiquetasPorHoja: [1, 2, 3, 4, 5],
      cantidadSeleccionada: 0,
      fechaPdf: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menuFecha: false,
      tipos: ["SIMPLE", "FRONT"],
      tipoEtiqueta: "",
      tipoRules: [(v) => !!v || "Tipo de entrega es requerida"],
      valid: false,
      objetoEnviado: "",
      noEtiquetasMensaje: "",
      noEtiquetas: false,
    };
  },
  computed: {
    deshabilitarPorCliente() {
      return (
        this.clienteSeleccionadoPorCliente === "" ||
        this.cantidadSeleccionada === 0
      );
    },
    deshabilitarPorPedido() {
      return (
        this.listaPedidos === "" || this.clienteSeleccionadoPorPedido === ""
      );
    },
  },
  methods: {
    datosUsuario () {
      // console.log(firebase.auth())
      return {
        email_user: firebase.auth().currentUser.email,
        name_user: firebase.auth().currentUser.displayName,
        uid: firebase.auth().currentUser.uid
      }
    },
    async listarClientes() {
      try {
        const respuestaClientes = await axios.get(
          "https://backendservices.rayoapp.com/Customers/customers",
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );
        console.log("Clientes listados!");
        this.clientes = respuestaClientes.data;
      } catch (error) {
        console.log(error);
      }
    },
    async generarEtiquetasPorCliente() {
      try {
        this.cargandoPdfCliente = true;
        this.deshabilitarPdfCliente = true;
        this.errorPdfCliente = false;
        this.noEtiquetas = false;
        this.noEtiquetasMensaje = "";
        // this.listaPedidosFiltrada = this.listaPedidos.split(/\r\n|\n|\r/);
        // this.listaPedidosFiltrada = this.listaPedidosFiltrada.filter(
        //   e => e !== ""
        // );
        // console.log(this.listaPedidosFiltrada);

        let objetoPdf = {
          initdate: this.fechaPdf,
          enddate: this.fechaPdf,
          idCustomer: this.clienteSeleccionadoPorCliente,
          cantidadEtiquetas: this.cantidadSeleccionada,
          
              emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime()
        };
        console.log(objetoPdf);

        this.objetoEnviado = objetoPdf;

        const respuesta = await axios.post(
          "https://southamerica-east1-rayo-dev.cloudfunctions.net/generarPdfEtiquetasCustom/generarPDF",
          objetoPdf
        );

        this.etiquetasPorCliente = respuesta.data.fileURL;

        if (this.etiquetasPorCliente === undefined) {
          console.log("Cliente sin etiquetas:", respuesta.data);
          this.noEtiquetas = true;
          this.noEtiquetasMensaje = respuesta.data.message;
          this.cargandoPdfCliente = false;
        } else {
          this.cargandoPdfCliente = false;
          console.log("Link etiquetas por cliente:", respuesta.data);
          this.deshabilitarPdfCliente = false;
        }
      } catch (error) {
        console.log(error);
        this.errorPdfCliente = true;
        this.cargandoPdfCliente = false;
      }
    },
    async generarEtiquetasPorPedidos() {
      try {
        this.cargandoPdfPedidos = true;
        this.deshabilitarPdfPedidos = true;
        this.errorPdfPedidos = false;
        this.listaPedidosFiltrada = this.listaPedidos.split(/\r\n|\n|\r/);
        this.listaPedidosFiltrada = this.listaPedidosFiltrada.filter(
          (e) => e !== ""
        );
        console.log(this.listaPedidosFiltrada);

        let objetoPdf = {
          // idQR: this.QR,
          // idOrders: this.listaPedidosFiltrada,
          // idCustomer: this.clienteSeleccionado,
          OrderID: "genericoRayoMiGPS",
          Imei: this.listaPedidosFiltrada,
          
              emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime()
        };
        console.log(objetoPdf);

        const respuesta = await axios.post(
          "https://ris.rayoapp.com/funcionesRayo/GeneraEtiquetamiGPS",
          objetoPdf
        );

        this.etiquetasPorPedidos = respuesta.data.Filename;
        this.cargandoPdfPedidos = false;
        console.log("Link etiquetas por pedidos:", respuesta.data.Filename);
        this.deshabilitarPdfPedidos = false;
      } catch (error) {
        console.log(error);
        this.errorPdfPedidos = true;
        this.cargandoPdfPedidos = false;
      }
    },
    limpiarTodo() {
      this.listaPedidos = "";
      this.cantidadSeleccionada = "";
      this.deshabilitarPdfPedidos = true;
      this.deshabilitarPdfCliente = true;
      this.cargandoPdfPedidos = false;
      this.cargandoPdfCliente = false;
      this.errorPdfPedidos = false;
      this.errorPdfCliente = false;
      this.clienteSeleccionadoPorPedido = "";
      this.clienteSeleccionadoPorCliente = "";
      this.cantidadSeleccionada = 0;
      this.noEtiquetasMensaje = "";
      this.noEtiquetas = false;
    },
  },
  mounted() {
    this.listarClientes();
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
  color: white;
}
</style>
